var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "spainhn-solid-red-background d-flex",
          staticStyle: { "min-height": "8vh", width: "100%" },
        },
        [
          _c("div", { staticClass: "d-flex ps-5 my-auto" }, [
            _c(
              "p",
              {
                staticClass: "my-auto pb-0 mb-0 spainhn-text",
                attrs: { role: "button" },
              },
              [_vm._v(_vm._s(_vm.$t("default.CART_DESCRIPTION_TEXT")) + " >> ")]
            ),
            _c(
              "p",
              { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("default.CART_RESUME_TEXT"))),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "text-center" }, [
          _c(
            "div",
            { staticClass: "py-5" },
            [
              _c(
                "p",
                {
                  staticClass: "text-spainhn",
                  staticStyle: { "font-weight": "600", "font-size": "15pt" },
                },
                [_vm._v("¡Reserva Realizada Correctamente!")]
              ),
              _c("font-awesome-icon", {
                staticClass: "text-success",
                attrs: { icon: "fa-circle-check", size: "5x" },
              }),
              _c(
                "p",
                {
                  staticClass: "text-spainhn mt-3",
                  staticStyle: { "font-weight": "600", "font-size": "15pt" },
                },
                [
                  _vm._v(
                    "Su código de pedido es: " + _vm._s(_vm.localizadorDePedido)
                  ),
                ]
              ),
              _c("p", { staticClass: "spainhn-small-text" }, [
                _vm._v(
                  "Recibirás por correo electrónico todos los detalles de tu reserva"
                ),
              ]),
              _c("p", { staticClass: "spainhn-small-text" }, [
                _vm._v(
                  "Recuerda que puedes acceder a tu lista de reservas desde el área de clientes"
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-modal",
        {
          staticClass: "text-center",
          attrs: { "hide-footer": "", "hide-header": "" },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("div", { staticClass: "py-5" }, [
            _c("p", { staticClass: "spainhn-subtitle-2-black text-center" }, [
              _vm._v(" Gracias por su compra "),
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "mdb-btn",
                  {
                    staticClass: "spainhn-button mx-auto",
                    attrs: { color: "indigo", size: "lg" },
                    on: {
                      click: function ($event) {
                        _vm.modalShow = false
                      },
                    },
                  },
                  [_vm._v("ACEPTAR")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }