<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height:8vh; width:100%">
            <div class="d-flex ps-5 my-auto">
                <p class="my-auto pb-0 mb-0 spainhn-text" role="button">{{ $t('default.CART_DESCRIPTION_TEXT') }} >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text"><strong>{{ $t('default.CART_RESUME_TEXT') }}</strong></p>
            </div>
        </div>
        <div class="container-fluid">
            
            <div class="text-center">
                <div class="py-5">
                    <p class="text-spainhn" style="font-weight: 600; font-size: 15pt;">¡Reserva Realizada Correctamente!</p>
                    <font-awesome-icon icon="fa-circle-check" class="text-success" size="5x"></font-awesome-icon>
                    <p class="text-spainhn mt-3" style="font-weight: 600; font-size: 15pt;">Su código de pedido es: {{localizadorDePedido}}</p>
                    <p class="spainhn-small-text">Recibirás por correo electrónico todos los detalles de tu reserva</p>
                    <p class="spainhn-small-text">Recuerda que puedes acceder a tu lista de reservas desde el área de clientes</p>

                </div>
            </div>
        </div>

        <b-modal v-model="modalShow" hide-footer hide-header class="text-center">
            <div class="py-5">
                <p class="spainhn-subtitle-2-black text-center">
                    Gracias por su compra
                </p>
                <div class="d-flex">
                    <mdb-btn color="indigo" class="spainhn-button mx-auto" size="lg" @click="modalShow = false">ACEPTAR</mdb-btn>
                </div>
            </div>
        </b-modal>

    </default-layout>

</template>

<script>
// import axios from 'axios'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import ApiService from '../services/ApiService'

export default {
    components:{
        DefaultLayout,
    },
    data(){
        return{
            // items:[]
            modalShow: true,
            Ds_SignatureVersion:null,
            Ds_MerchantParameters:null,
            Ds_Signature:null,
            Ds_Mount: '0',

            loading:false,
            entradas: [],
            localizadorDePedido:null,
            order_id: null,
            revenue: null,
        }
    },
    methods:{

    },
    async mounted(){
        let $vm = this;
        $vm.$store.commit('removeAllItems');
        $vm.Ds_SignatureVersion = $vm.$route.query.Ds_SignatureVersion;
        $vm.Ds_Signature = $vm.$route.query.Ds_Signature;
        $vm.Ds_MerchantParameters = $vm.$route.query.Ds_MerchantParameters;
        const respuesta = await ApiService.redsysClientConfirmation($vm.Ds_Signature, $vm.Ds_MerchantParameters, $vm.Ds_SignatureVersion)
            // .then( res => {
            //     $vm.localizadorDePedido = res.data.localizador;
            // })
            // .catch(err => {
            //     console.log(err);
            // })

        const { localizador, numeroDePedido, total } = respuesta.data

        console.log( `Localizador: ${localizador}, numero de pedido: ${numeroDePedido}, total: ${total}` )

        $vm.localizadorDePedido = localizador;
        $vm.order_id = numeroDePedido;
        $vm.revenue = total;

        let Script = document.createElement("script");
        Script.setAttribute("src", `https://secure.adnxs.com/px?id=1290621&seg=27097243&order_id=${this.order_id}&value=${this.revenue}&t=1`)
        Script.setAttribute("type", "text/javascript")
        document.body.appendChild(Script);
    },
    computed:{
        idiomaIndex(){
            let $vm = this;
            // console.log($vm.$store.getters.idiomaIndex)
            return $vm.$store.getters.idiomaIndex;
        },        
    }
}
</script>